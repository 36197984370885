﻿/*

# Core

*/
@use "sass:math";

html, body {
    height: 100%;
}

body {
    background-color: $grey;
    color: $text;
    padding-bottom: 350px;

    font: {
        family: $futuraPTBook;
        weight: 300;
    }

    -webkit-font-smoothing: antialiased;

    @include fontSizeAndLineHeight( 16px, 22px );
    min-width: 320px;

    @include legacyIE {
        width: 770px;
        margin: 0 auto;
    }

    .body-overlay {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        z-index: 2;
        opacity: 0.2;
    }

    .header-background {
        position: absolute;
        width: 100%;
        background-color: #001439;
        height: 22px;
        z-index: 1;
    }
}


::-webkit-input-placeholder {
    color: $white;
    opacity: 0.5;
}

:-moz-placeholder {
    color: $white;
    opacity: 0.5;
}

::-moz-placeholder {
    color: $white;
    opacity: 0.5;
}

:-ms-input-placeholder {
    color: $white;
    opacity: 0.5;
}
/*

## Typography and text styles

### Headings 

    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>

*/

h1, h2, h3, h4, h5, h6 {
    line-height: 1.375;
}

h1 {
    padding: 30px 0 15px;
    margin: 0;
    word-break: break-word;

    font: {
        family: $futuraPT;
        weight: normal;
    }

    &.largeHeader {
        @include desktop-large {
            font-size: rem(70px);
            letter-spacing: -3px;
            width: 100%;
        }

        @include desktop {
            font-size: rem(67px);
            letter-spacing: -3px;
            width: 100%;
        }

        @include tablet-up {
            line-height: 1;
        }

        @include tablet {
            font-size: rem(50px);
            letter-spacing: -2px;
            width: 90%;
        }

        @include phone {
            font-size: rem(28px);
            width: 100%;
            padding-top: 35px;
        }
    }



    &.hasBorder {
        border-bottom: 1px solid $border-grey;
    }

    &.standalone {
        max-width: $content-max-width;
        padding: 0 $site-padding;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $grid-height;

        @include media-tablet {
            margin-bottom: $grid-height-x2;
        }

        &.in14cols {
            @include media-min-width( paddedCols( 14 ) ) {
                padding: 0 $site-padding + $column-width;

                &.hasBorder:after {
                    right: $site-padding + $column-width;
                    left: $site-padding + $column-width;
                }
            }
        }

        &.hasBorder {
            border: none;
            position: relative;
            z-index: 1;

            &:after {
                content: "";
                display: block;
                height: 5px;
                background-color: $border-grey;
                position: absolute;
                right: $site-padding;
                bottom: $grid-height-half * -1;
                left: $site-padding;
            }
        }
    }
}




h2, .mediumHeader {
    font: {
        family: $futuraPTn7;
        weight: normal;
    }

    margin: 0;
    font-size: 2.5rem;
    padding: em($grid-height, 20px) 0 0;
    letter-spacing: -0.8px;

    &.blockSectionHeader {
        padding: 0 0 ( $grid-height - 5px );
    }

    &.sectionHeader {
        &.tight {
            margin-bottom: ( $grid-height * 0.5 ) - 3px;
        }
    }

    .semiFooter &.sectionHeader {
        margin: 0 0 ( $grid-height + $grid-height-half - 5px );
    }

    &.serif {
        font: {
            family: $font-serif;
            size: rem(30px);
            weight: normal;
        }
    }



    @include tablet-down {
        font-size: rem(24px);
        letter-spacing: -0.4px;
        width: 90%;
    }
}

h3, .smallHeader {
    font: {
        family: $futuraPTn7;
        weight: normal;
    }

    word-break: break-word;
    margin: 15px 0 0 0;
    font-size: 2rem;

    @include desktop-up {
        letter-spacing: -1.5px;
    }

    @include tablet-down {
        font-size: 1.3rem;
    }

    @include tablet {
        letter-spacing: -1.5px;
    }

    @include phone {
        letter-spacing: -1px;
    }
}

h4, h5, h6, .tinyHeader {
    font: {
        family: $futuraPTn7;
        weight: normal;
    }

    margin-bottom: 0;
    font-size: 1.5rem;
}

/*

### Paragraphs and hyperlinks 

    <p>Paragraph #1</p>
    <p>Paragraph #2</p>

    <a href="#">Regular link</a>
    <a href="#" class="visited">Visited link</a>
    <a href="#" class="active">Active link</a>
    
*/

p {
    font-family: $futuraPTBook;
    font-size: $base-font-size;
    margin: 0 0 em($grid-height-half, 16px);

    @include phone {
        font-size: $base-font-size-phone;
        letter-spacing: -0.2px;
        line-height: 24px;
    }
}

blockquote {
    position: relative;
    z-index: 1;
    @include fontSizeAndLineHeight( 18px, 26px );

    font: {
        family: $font-serif;
        style: italic;
    }

    padding: 0 $site-padding $grid-height-x2;
    margin: $grid-height 0;

    @include media-small-tablet {
        @include fontSizeAndLineHeight( 22px, 34px );
        padding: 0 $column-width $grid-height-x2;
    }

    &:before {
        content: open-quote;
        display: inline-block;
        position: absolute;
        top: -15px;
        left: -13px;
        color: $light-blue;
        font-size: 80px;
        line-height: 1;

        @include media-small-tablet {
            font-size: 120px;
            left: 0;
        }
    }

    &:after {
        content: close-quote;
        display: inline-block;
        @include visuallyHidden;
    }

    cite {
        position: absolute;
        bottom: $grid-height-half;
        left: $site-padding;
        display: inline-block;

        font: {
            family: $font-main;
            style: normal;
            size: rem(14px);
        }

        line-height:  math.div($grid-height, 14px) ;
        text-transform: uppercase;
        background-color: $medium-grey;
        color: $text;
        padding: 0 1em;

        @include media-small-tablet {
            left: $column-width;
        }
    }
}

a {
    text-decoration: none;
    font-family: $futuraPTn7;

    .Green & {
        color: $dark-green;
    }

    .Blue & {
        color: $blue;
    }

    .Default & {
        color: $default;
    }


    .Orange & {
        color: $dark-orange;
    }

    &:hover {
        text-decoration: underline;
    }
}

th,
td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #E1E1E1;
}

th:first-child,
td:first-child {
    padding-left: 0;
}

th:last-child,
td:last-child {
    padding-right: 0;
}

.BodyContent {
    p {
        word-break: break-word;
        margin-bottom: $site-padding;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    img {
        max-width: 100%;
    }

    a {
        font-family: $futuraPTBook;
        font-size: $base-font-size;

        @include phone {
            font-size: $base-font-size-phone;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        margin: $site-padding 0 em($grid-height-half, 16px);
        padding: 0;
        padding-left: 18px;
        font-size: $base-font-size;

        @include phone {
            font-size: $base-font-size-phone;
        }

        li {
            position: relative;
            z-index: 1;
            margin: 0 0 $grid-height-half;
            text-align: inherit;

            &[dir=rtl] {
                margin-right: 20px;
            }

            a {
                text-decoration: underline;
                font-size: $base-font-size;

                &:hover {
                    text-decoration: none;
                }



                @include phone {
                    font-size: $base-font-size-phone;
                }
            }
        }
    }

    ol {
        margin: em($grid-height-half, 16px) 0 em($grid-height, 16px);
        padding: 0 0 0 18px;

        li {
            margin: 0 0 $grid-height-half;
            padding: 0;
        }
    }
}



.preamble {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin: 0 0 em($grid-height, 20px);
    padding: 0;
}

.byline,
.caption {
    @include fontSizeAndLineHeight( 14px, 14px );
    font-family: $futuraPTBook;
    font-style: italic;
}

.byline {
    position: absolute;
    bottom: 30px;
    padding: 3px 6px 3px 25px;
    color: $white;
    background-color: rgba(0,0,0,0.55);

    .ThemeTeaser & {
        padding-left: 25px;
        bottom: 0px;
    }
}

.caption {
    padding: 5px 5px 5px 8px;
    color: #565252;
    max-width: 67%;

    @include tablet-down {
        max-width: 87%;
    }
}

.video {
    @include fontSizeAndLineHeight( 13px, 13px );
    padding: 5px 0;
}

/*

### Horizontal rulers

    <hr />

    <hr class="sectionSeparator" />

    <hr class="mountainTops" />

    <hr class="mountainTops blue" />
    
*/

hr {

    &.sectionSeparator {
        height: 5px;
        color: $medium-grey;
        background-color: $medium-grey;
        max-width: $content-max-width;
        margin: 0 auto;

        &.thin {
            height: 3px;
        }

        &.full {
            max-width: none;
        }

        &.lightBlue {
            color: $light-blue;
            background-color: $light-blue;
        }
    }



    &.mountainTops {
        height: 22px;
        color: transparent;
        @include svg("mountains/green.svg", "mountains/green.png");

        background: {
            color: transparent;
            position: left bottom;
            repeat: repeat-x;
            size: auto 22px;
        }
        //max-width: $content-max-width;
        margin: 5px auto;

        &.blue {
            @include svg("mountains/blue.svg", "mountains/blue.png");

            &.opaque {
                @include svg("mountains/blue-opaque.svg", "mountains/blue.png");
            }

            &.withBorder {
                height: 25px;
                border-bottom: 3px solid $light-blue;
            }
        }
    }
}

.Mesh {
    height: 190px;
    background-size: auto 190px;
    @include svg("mountains/mesh.svg", "mountains/mesh.png");
}

/*

# Containers

## .section

Section container is used to wrap an area so it gets the correct width
and clearfix floats inside.

Nested sections will have padding removed so sections always get correct width
even if nested. **Nesting should be avoided!**

IE8 and older gets mobile styling and a fixed width of 770px;

contentSection does not have padding.

*/
.section,
.contentSection {
    position: relative;
    z-index: 1;
    max-width: $site-max-width;
    margin: 0 auto;

    .section {
        padding: 0;
    }
}

.contentSection {
    max-width: $content-max-width;
    padding: 0;

    @include desktop-large {
        max-width: $desktop-large;
    }

    @include desktop-large-down {
        max-width: $desktop;
        margin: 0 auto 0 auto;
    }

    @include desktop-down {
        max-width: $desktop;
        margin: 0 20px 0 20px;
    }

    @include tablet-down {
        max-width: $tablet;
        margin: 0 20px 0 20px;
    }
}

.semiFooter {
    background-color: $menu-back;
    border-top: 1px solid $footer-back;
    border-bottom: 1px solid $footer-back;
    padding: ( $grid-height * 1.5 ) 0;

    .Twitter, .Facebook {
        margin: 0 10px;
    }

    + .semiFooter {
        border-top: 2px solid $menu-back;
        margin-top: -1px;
        padding-top: 0;
    }
}

.contentArea {
    padding: ( $grid-height * 1.5 ) 0;
}

.padding-right {
    padding-right: 40px;
}

/*

## .container

Container is only for clearfixing floats.
*/

.container {
    @extend %clearfix;
}


.logo {
    // Image inline to get plain URL
    @include imageReplace(150px, 20px);

    background: {
        position: 0 0;
        size: 150px auto;
    }
}


/*

## Question mark icon

SVG logo with PNG fallback.

    <h2 class="sectionHeader icon rect questionMark">Fragor och svar om branden</h2>

*/
.icon {
    background: {
        position: 0 50%;
        repeat: no-repeat;
    }
    //    &.rectQuestionMark {
    //        @extend %rectangular-question-mark;
    //        background-position: 0 4px;
    //        padding-left: 43px;
    //     }
}


/*

## Hamburger menu icon

The hamburger uses no image, only CSS pseudo elements. 
    
    <div class="whopper"></div>
    
*/
.whopper {
    position: relative;
    display: inline-block;

    &,
    &:before,
    &:after {
        background-color: #fff;
        width: 45px;
        height: 3px;
        z-index: 1;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
    }

    &:before {
        top: 6px;
        left: 0;
    }

    &:after {
        bottom: 6px;
        left: 0;
    }
}

/*

## Photographer text on image

    
    <span class="Photographer">Foto: V. Hasselblad</div>
    
*/

.Photographer {
    display: block;
    max-width: 288px;
    @include fontSizeAndLineHeight( 11px, 16px );
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-small-tablet {
        @include fontSizeAndLineHeight( 14px, 30px );
    }
}


/*

## Skiplinks

Skiplinks are visually hidden. When focused they are visible (for keyboard navigation).

    <div class="Skiplinks">
        <a href="#main">Hoppa till huvudinnehallet</a>
    </div>     
*/

.Skiplinks {
    @include visuallyHidden;

    a:focus {
        position: fixed;
        z-index: 9999;
        top: 5em;
        left: 1em;
        background-color: $black;
        background-color: rgba( $black, .8 );
        color: $white;
        font-weight: bold;
        padding: 1em;
        border: 5px solid $alert-yellow;
        border-radius: .5em;
    }
}

/*

## Visually hidden elements

Visible for screenreaders. Off screen. Never visible on screen.

    <div class="vh">
        Whatever.
    </div> 

*/

.vh { // Visually Hidden
    @include visuallyHidden;
}


/*

## Aurally hidden elements

Hidden for screenreaders. 

    <div aria-hidden="true">
        Whatever.
    </div> 

*/
[aria-hidden=true] {
    speak: none;
}

/*

## Vertical Spacers

No content, just space. Vertical.

*/

.in10to4 {
    .vspaceH1 {
        @include media-min-width(840px) {
            margin-bottom: ( $grid-height * 2.5 );
        }
    }
}


.faq-filter {

    h2 {
        margin: 0;
    }

    .Search {
        margin: 3px 0 7px;
        max-width: cols(8);

        .bd {
            border: none;
            padding: 0;

            button[type=submit] {
                top: 0;
                right: 10px;
                padding-top: 41px;
                background-size: 20px auto;

                @include inlineSvg("icons/magnifying-glass-white.svg", "icons/magnifying-glass-white.png");
            }

            input[type=search] {
                color: $white;
                text-indent: 15px;
                height: 42px;
            }
        }

        .box {
            background-color: $dark-blue-mesh;
        }
    }

    .suggestions {
        margin: $grid-height 0;

        p {
            line-height: 1;
        }
    }

    .thisPage {
        margin: 0;
        @include fontSizeAndLineHeight( 13px, $grid-height );
        color: $medium-dark-grey;
    }

    .in10to4 .ArticlesList {
        margin: 0 0 ( $grid-height * 2 );

        .highlight {
            background-color: $alert-yellow-light;
        }

        > .hd {
            padding: 0;
        }

        > ul > li {
            border-bottom: 1px solid $border-grey;
            padding-bottom: $grid-height-half;
            padding-top: $grid-height-half - 1px;

            &:last-child {
                border: none;
            }

            > .hd,
            > .bd,
            > .ft {
                padding: 0 10px;
            }
        }
    }

    .ArticlesList > ul .ft .TimeStamp {
        margin: 0 10px 0 0;
        border: none;

        > span {
            margin-left: 0;
        }
    }

    .source {
        display: inline-block;
        @include fontSizeAndLineHeight( 14px, $grid-height );
    }

    .url {
        @include fontSizeAndLineHeight( 13px, $grid-height );
    }
}

input#filter {
    &::-webkit-input-placeholder {
        color: #fff;
        opacity: 1;
    }
}


.ft {
    padding-top: $site-padding;
    font-size: .875rem;

    .head {
        &.tags {
            border-bottom: solid 3px #cfdaea;
            margin-bottom: 10px;
            margin-top: 25px;
            float: left;
            display: block;
            clear: both;
            width: 99%;

            .left {
                float: left;
                display: block;
            }

            .right {
                float: right;
                display: block;

                @include media-max-width(500px) {
                    float: left;
                    padding-right: 10px;
                }
            }
        }
    }

    .TagList {
        clear: both;
        margin-bottom: 10px;
    }
}

.startpage-heading {
    color: $dark-blue;
    padding-top: 1rem;
    text-transform: uppercase;

    span {
        display: block;
        font-size: 20px;
        text-transform: none;
    }
}

.Article, .EventPage, .FactsPage {
    .Hero {
        padding-left: 0;
    }

    .updated {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    .header {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        h1 {
            padding-top: 0;
        }
    }
}
