

// ==|== Colors not in palette =============================================================== //

$primary-green: #007E83;

$white: #FFF;
$offwhite: #fafaf9;

$black: #000;
$totally-black: #000;
$offblack: #333;
$almost-black: #333;

$dark-dark-grey: #666;
$darker-grey: #75828c;
$dark-grey: #999;
$medium-dark-grey: #D5D5D5; 
$medium-grey: #E5E5E5;
$another-grey: #e7e7e7;
$deep-purple: #3d059d;
$purple: #5B0592;
$red: #D3191D;

$gold-yellow: #FECF07;
$light-gold-yellow: #FFD700;

$dark-grey-blue: #2d3d4c;
$grey-blue: #445362;

$dark-blue-mesh: #344655;
$light-blue-mesh: #53616f;

$share: #1854BB; 
$email-green: #109552;
$email-grey: #61799c;

$grey-blue-lilla: #445362;

$border-grey: $medium-grey;

$pale-red: #e999a8;
$pale-pale-red: #f6d1d2;

$coolgray-11: #4a4944;
$coolgray-1: #e4e4e1;

$light-gray: #e6e6e6;
$lighter-gray: #efefef;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;

// ==|== Base =============================================================== //

$text: #000000;
$footer-back: #EDEDED;
$menu-back: #FAFAFA;
$light-blue: #83AFD5;
$pale-light-blue: #F2F7FB;
$pale-blue: #C1D7EA;
$pale-pale-blue: #e6EFF7;
$dark-mesh-blue: #344655;
$alert-yellow: #FFCF00;
$alert-yellow-light: #FFE77F;
$warning-red: #BB0A22;
$warning-red-light: #F2BDC5;
$yellow: #ffcf00;

$default: #58728D;
$green: #008382;
$light-green: #009d95;
$dark-green: #007f7e;
$dark-blue: #001439;
$blue: #1854BB;
$light-blue: #326CD1;
$orange: #c15712;
$light-orange: #e36821;
$dark-orange: #c04c00;
$grey: #ecf1f6;
$grey-blue: #21365a;
$border-grey: #fafbfd;
$bg-grey: #fafbfd;
$dark-header: #000a1d;
$puff-bg: #f6f8fb;


//Tabbed color
$tabbed: #212c8d;

// Grays
$msb-gray: #6f6e67;
$grey-100: #5B5B5B;
$grey-90: #6B6B6B;
$gray-80: #8f8e88;
$gray-60: #a9a8a4;
$gray-40: #cacac5;
$gray-20: #e5e5e2;
$gray-10: #F7F7F7;
$coolgray-11: #4a4944;
$coolgray-1: #e4e4e1;


// ==|== Complementary (BETA) =============================================================== //

$comp-brown: #785405;
$comp-brown-40: #C8B89F;
$comp-brown-20: #e4DCCF;
$comp-green: #109552;
$comp-green-40: #8AD5BC;
$comp-green-20: #C5EADD;
$comp-orange: #E69214;
$comp-orange-40: #F5D3A5;
$comp-orange-60: #FAE9D2;

$geotag-color-light: #FBEBD0;
$tag-color-light: #D0ECDC;
$pagetype-color-light: #E6EFF7;

$geotag-color: #F5D3A5;
$tag-color: #8AD5BC;
$pagetype-color: #C1D7EA;
$theme-text-bg: #E2E7EC;

