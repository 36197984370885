
// List of blocks inside a block
@use "sass:math";

.BlockSection > .Block > div:not([class]) > div:not([class]) {
    margin: 0 0 ( $grid-height * 1.5 );
}

.BlockContentArea {
    margin-top: $site-padding;

    > div {
            > div:not(.BlockSectionWrapper):not(.ThemeTeaser) {
            @extend %clearfix;
            position: relative;
            /*z-index: 1;*/
            margin: 0 auto;

            @include desktop-large {
                max-width: $desktop-large;
            }

            @include desktop-large-down {
                max-width: $desktop;
            }

            @include desktop-down {
                max-width: $desktop;
                margin: 0 $site-padding;
            }

            @include tablet-down {
                max-width: $tablet - $site-padding-x2;
                margin: $site-padding;
            }
        }
    }
}

.BlockSectionWrapper {
    position: relative;
    z-index: 1;
    max-width: none;
    /*padding: ( $grid-height * 1.0 ) 0;*/
    margin: 0;

    &.hasInnerPadding {
        padding: 0;
    }
    &.hasGreyBg {
		.Block .PageList > .hd h2 {
			border-bottom-color: $menu-back;
		}
   
    }
}


ul.BlockList {
    list-style-type: none;
	margin: 0;
	padding: 0;
}



ul.BlockList,
.BlockSection {

    &.top {
        margin-top: $site-padding;
    }

    &.top, &.NewsBlockView {
        padding: 0;
        min-height: 25px;

        @include tablet-down {
            margin: $site-padding;
        }
    }

    @extend %clearfix;
    position: relative;
    z-index: 1;
    max-width: $site-max-width;
    margin: 0 auto;
    max-width: $content-max-width;
    padding: 0;

    @include desktop-large {
        max-width: $desktop-large;
        /* padding-left: 50px;*/
    }

    @include desktop-large-down {
        max-width: $desktop;
        /* padding-left: 40px;*/
    }

    @include desktop-down {
        max-width: $tablet;
        margin: 0 auto;
    }

    @include tablet-down {
        max-width: $tablet;
        margin: 0 auto;
    }

    .section &,
    .BlockSection & {
        padding: 0 0 $grid-height;

        &.NewsBlockView {
            margin: 0;
            padding-bottom: 0;
        }
    }





    &.in12cols {
        max-width: paddedCols(12);

        @include media-min-width( paddedCols( 16 ) ) {
            max-width: $site-max-width;
            padding: 0 ( $site-padding + ( $column-width + $gutter-width ) * 2 ) $grid-height;
        }
    }

    &.in11cols {
        max-width: paddedCols(9);

        @include media-min-width( paddedCols( 11 ) ) {
            max-width: $site-max-width;
            padding: 0 ( $site-padding + $column-width + $gutter-width ) $grid-height;
        }
    }
}

.GridSection {
    @extend %clearfix;
    position: relative;
    z-index: 1;
}


ul.BlockList > li {
    display: block;
}

.Block {
    padding: 0;
    min-height: 1px;
    margin-bottom: $grid-height;

	@include media-tablet {
        @include basic {
			padding-bottom: $grid-height;
		}
    }
    @include basic {
        padding-bottom: 0;
    }
}

li,
.Block {
    position: relative;
    z-index: 1;
    
    // Do not allow nested BlockSections
    .BlockSection .BlockSection & {
        float: none !important;
        width: auto !important;
        padding: 0 !important;
        margin-bottom: 0;
    }

    .inHalves > & {
        
        @include tablet-up {
            float: left;
            width: percentage( math.div( cols( 8 ) + $gutter-width-half , cols( 16 )) );
            padding: 0 $gutter-width-half 0 0;
            margin-bottom: 0;

            &:nth-child(odd) {
                clear: left;
            }

            &:nth-child(even) {
                padding: 0 0 0 $gutter-width-half;
            }
        }
    }

    .inThirds > & { // Does not fit into grid!
        
        @include media-min-width( cols( 8 ) ) {
            float: left;
            width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 8 )) );
            padding: 0 $gutter-width-half 0 0;
            
            &:first-child {
                float: none;
                width: auto;
                padding: 0;
            }
            &:nth-child(n+2) {
                margin-bottom: 0;
            }
            &:last-child {
                padding: 0 0 0 $gutter-width-half;
            }
        }
                
        @include media-min-width( cols( 12 ) ) { 
            float: left;
            width: percentage( math.div( cols( 4 ) + $gutter-width - 7px, cols( 12 )) );
            padding: 0 7px 0 7px;
            margin-bottom: 0;

            &:first-child {
                float: left;
                width: percentage( math.div(cols( 4, $gutter-width - 7px ), cols( 12 )) );
                padding: 0 ( $gutter-width - 7px ) 0 0;
            }
            &:last-child {
                padding: 0 0 0 ( $gutter-width - 7px );
            }
        }
    }

    .inFourths > & {
        
        @include media-min-width( cols( 8 ) ) {
            float: left;
            width: percentage( math.div( cols( 8 ) + $gutter-width-half , cols( 16 )) );
            padding: 0 $gutter-width-half 0 0;
            
            &:nth-child(3) {
                clear: both;
            }
            &:nth-child(3),
            &:nth-child(4) {
                 margin-bottom: 0;
            }
            &:nth-of-type(even) {
                padding: 0 0 0 $gutter-width-half;
            }
        }
        @include media-min-width( paddedCols( 15 ) ) {
            padding: 0 $gutter-width 0 0;
            width: percentage( math.div( cols( 15*0.25 ) + $gutter-width , cols( 15 )) );
            margin-bottom: 0;

            &:nth-child(3) {
                clear: none;
            }
            &:nth-of-type(even) {
                padding: 0 $gutter-width 0 0;
            }
            &:last-child {
                padding: 0;
                width: percentage( math.div(cols( 15*0.25 ), cols( 15 )) );
            }
        }
        @include media-min-width( cols( 16 ) ) {
            padding: 0 $gutter-width 0 0;
            width: percentage( math.div( cols( 4 ) + $gutter-width , cols( 16 )) );

            &:nth-of-type(even) {
                padding: 0 $gutter-width 0 0;
            }
            &:last-child {
                padding: 0;
                width: percentage( math.div(cols( 4 ), cols( 16 )) );
            }
        }
    }


    .inThirds.in25to50 > & {
        @include media-min-max-width( cols( 8 ), cols( 12 ) - 1px  ) {
            float: left;
            width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 8 )) );
            padding: 0 0 $grid-height $gutter-width-half;
            
            &:first-child {
                padding: 0 $gutter-width-half $grid-height 0;
            }
            &:last-child {
                float: none;
                clear: both;
                width: auto;
                padding: 0;
            }
        }
        @include media-min-width( cols( 15 ) ) { 
            width: percentage( math.div( cols( 4 ) + $gutter-width , cols( 16 )) );
            padding: 0 $gutter-width 0 0;

            &:last-child {
                width: percentage( math.div( cols( 8 ) , cols( 16 )) );
                padding: 0;
            }
        }
    }

    .inThirds.in50to25 > & {
        @include media-min-width( cols( 15 ) ) { 
            width: percentage( math.div( cols( 4 ) + $gutter-width , cols( 16 )) );
            padding: 0 0 0 $gutter-width;
            
            &:first-child {
                width: percentage( math.div( cols( 8 ) , cols( 16 )) );
                padding: 0;
            }
        }
    }

    .in12to4 > & {

        @include media-min-width( cols( 10 ) ) {
            float: left;
            width: percentage( math.div( cols( 6 ) + $gutter-width-half , cols( 10 )) );
            padding: 0 $gutter-width-half 0 0;
            margin-bottom: 0;

            &:last-child {
                width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 10 )) );
                padding: 0 0 0 $gutter-width-half;
            }
        }

        @include media-min-width( cols( 12 ) ) {
            width: percentage( math.div( cols( 8 ) + $gutter-width-half , cols( 12 )) );
            
            &:last-child {
                width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 12 )) );
            }
        }
        
        @include media-min-width( paddedCols( 15 ) ) {
            width: percentage( math.div( cols( 12 ) + $gutter-width-half , cols( 16 )) );
            
            &:last-child {
                width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 16 )) );
            }
        }
    }

    .in10to4 > & {
         @include media-min-width( cols( 10 ) ) {
            float: left;
            width: percentage( math.div( cols( 6 ) + $gutter-width-half , cols( 10 )) );
            margin-bottom: 0;

            &.side-menu {
                width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 10 )) );
            }
        }

        @include media-min-width( cols( 11 ) ) {
            width: percentage( math.div( cols( 8 ) + $gutter-width-half , cols( 12 )) );
            
            &.side-menu {
                width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 12 )) );
            }
        }

        @include media-min-width( paddedCols( 12 ) ) {
            width: percentage( math.div( cols( 10 ) + $gutter-width-half , cols( 14 )) );
            
            &.side-menu {
                width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 14 )) );
            }
        }

        @include media-min-width( paddedCols( 14 ) ) {
            width: percentage( math.div( cols( 10 ) + $gutter-width-half , cols( 14 )) );
            
            &.side-menu {                
                width: percentage( math.div( cols( 4 ) + $gutter-width-half , cols( 14 )) );
            }
        }
    }

    .in12cols > & {
        max-width: cols(12); 
    }

    .in11cols > & {
        max-width: cols(11); 
    }

    .in10cols > & {
        max-width: cols(10);
        margin: 0 auto;
    }
}
