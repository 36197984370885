@use "sass:math";

#banner {
    position: relative;
    margin-bottom: 0;
    @include underlineLinksOnHoverOnly;
    z-index: 2;
    background: $dark-blue;

    .navWrapperContainer {
        padding-bottom: 0;
        top: 0px;
        height: 115px;

        &:nth-child(2) {
            height: 20px;

            @include desktop-up {
                min-height: 60px;
                height:auto;
            }
        }

        .StartPage & {
            height: 115px;
            display: block;

            @include tablet {
                height: 100px;
            }

            @include phone {
                height: 90px;
            }

            &.main-nav {
                height: auto;
            }

            &:nth-child(2) {
                @include tablet {
                    height: 70px;
                }

                @include desktop-down {
                    height: 0;
                }
            }
        }

        @include desktop-down {
            padding-bottom: 10px;
            height: 70px;
        }

        @include phone {
            height: 90px;
        }

        .navWrapper {
            @extend %clearfix;
            margin: 0 auto;
            position: relative;

            @include desktop-large {
                max-width: $desktop-large;
            }

            @include desktop-large-down {
                max-width: $desktop;
            }

            @include desktop-down {
                max-width: 100%;
            }
        }
    }

    [role=search] {
        padding: 0;

        @include media-small-tablet {
            padding: 2px 0 20px;
        }

        @include media-large-tablet {
            float: right;
            width: percentage( math.div(900, 900) );
            border: none;
        }

        @include media-desktop {
            width: percentage( math.div(1260, 1260) );
            padding: 5px 0 25px;
        }
    }

    [role=navigation] {
        @include media-large-tablet {
            float: left;
        }
    }
}


.Header {
    background-color: $dark-blue;
    height: 48px;

    .logo-container {
        z-index: 2;
    }

    .logo {
        position: absolute;
        top: 22px;
        background-image: url('/assets/img/logo/krisinformation_undertext_alt.svg');
        background-size: 380px auto;
        @include imageReplace(600px, 72px);
        background-size: 510px auto;

        .english & {
            background-image: url('/assets/img/logo/krisinformation_undertext_en.svg');
        }


        @include desktop-down {
            @include imageReplace(400px, 72px);
            background-size: 400px auto;
            margin-left: $site-padding;
        }


        @media (max-width: 768px) {
            width: 60%;
            background-size: 100% auto;
        }

        @media (max-width: 640px) {
            width: 60%;
            background-size: 100% auto;
        }

        @media (max-width: 520px) {
            width: 65%;
            background-size: 100% auto;
        }


        @media (max-width: 400px) {
            width: 75%;
            background-size: 100% auto;
        }
    }

    .toolNav {
        text-align: right;

        .search {
            background-color: $yellow;
            padding: 6px;
            height: 44px;
            text-transform: uppercase;

            button {
                background: none;
                border: none;
            }
        }
    }
}

.Navigation {
    @include underlineLinksOnHoverOnly;
    width: 100%;

    .toNav {
        color: white;
        //display: flex;
        /*align-items: flex-start;*/
        position: absolute;
        right: 0;
        top: -54px;
        line-height: 13px;
        height: 55px;
        width: 52px;
        text-transform: uppercase;
        font-family: "FuturaPTWebHeavy",Helvetica,Arial,sans-serif;
        background: url('/assets/img/icons/menu.svg');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center 0px;
        font-size: 12px;
        text-align: center;
        border: 0;
        /*justify-content: center;*/
        > span {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        &.Green {
            background-color: $green;
        }

        &.Blue {
            background-color: $blue;
        }

        &.Orange {
            background-color: $orange;
        }

        &:hover {
            text-decoration: none;
        }

        @include desktop-up {
        }

        @include media-min-width(961px) {
            display: none;
        }


        .StartPage & {
            @include desktop-down {
                top: -80px;
            }

            @include tablet-down {
                top: -70px;
            }
        }

        @include tablet-down {
            margin-top: 0px;
            margin-right: 0;
            display: block;
            top: -70px;
        }
    }


    .mainNav {
        .submenu {
            display: none;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            box-shadow: 0px 50px 40px -10px rgba(0,0,0, 0.3);
            line-height: 1.375;

            @include tablet {
                //top: 76px;
            }

            a {
                color: white;
                font-size: 1.1rem;
            }

            .StartPage & {
                //top: 96px;
                @include tablet {
                    //top: 76px;
                }
            }

            &:hover {
                @include tablet-up {
                    display: block;
                }
            }

            .first-col {
                padding: 0 30px 100px 40px;
                width: 33.5%;
            }

            .second-col {
                width: 33.5%;
            }

            .third-col {
                width: 32%;
            }

            .first-col,
            .second-col,
            .third-col {
                color: white;
                display: block;
                float: left;
                vertical-align: top;

                &:not(:first-child) {
                    border-left: 10px solid rgba(255,255,255, 0.4);
                    padding-left: 5px;
                }
            }


            .submenu-link {
                padding: 8px 10px;
                list-style: none;
                font-family: $futuraPTn7;

                &.header {
                    margin-bottom: 7px;
                    text-transform: uppercase;
                }

                &.footer {
                    margin-top: 7px;
                }
            }

            .menu-heading {
                font-size: 2em;
                padding: 30px 0 15px;
                margin: 0;
                word-break: break-word;
                font-family: "FuturaPTWebBold",Helvetica,Arial,sans-serif;
                font-weight: bold;
            }
        }

        .submenu--Green {
            background-color: $green;
        }

        .submenu--Blue {
            background-color: $blue;
        }

        .submenu--Orange {
            background-color: $orange;
        }

        .mobile-menu {
            margin-top: 0;
            padding: 0;
            position: fixed;
            list-style: none;
            right: 0;
            top: 0;
            width: 100%;
            max-width: 450px;
            overflow-x: hidden;
            background-color: $dark-blue;
            height: 100%;
            display: none;
            z-index: 2;
            -webkit-box-shadow: 0px 0px 179px 30px rgba(0,0,0,0.85);
            -moz-box-shadow: 0px 0px 179px 30px rgba(0,0,0,0.85);
            box-shadow: 0px 0px 179px 30px rgba(0,0,0,0.85);
            padding-bottom: 230px;

            span.mobile-menu-header {
                pointer-events: none;
            }

            .menu-item-top {
                z-index: 1;
            }

            .open-modal {
                background: none;
                border: none;
                color: white;
                margin-left: 20px;
                font-size: 18px;
                line-height: 1.09;
                font-family: "FuturaPTWebHeavy",Helvetica,Arial,sans-serif;
                padding: 0;
            }

            .search {
                text-transform: none;
            }

            .mobile-menu-header {
                color: #fff;
                margin-left: 20px;
                font-size: 18px;
                line-height: 1.09;
                /*font-family: $futuraPTExtra;*/
                display: inline-block;
                width: 80%;
                padding: 15px 0;
                position: relative;
                font-family: $futuraPTBook;

                &.current {
                    font-family: $futuraPTn7;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 100%;
                        background-color: #FFF;
                        top: 0;
                        left: -20px;
                    }
                }
            }

            .menu-controls {
                background-color: $grey;
                padding: 20px 0 15px 20px;

                .menu-title,
                .back-button {
                    padding-bottom: 0;
                    padding-top: 0;
                    padding-right: 0;
                    border: 0;
                    margin: 0;
                    color: $dark-blue;
                    font-size: 18px;
                    line-height: 1.09;
                    letter-spacing: -0.7px;
                    font-family: $futuraPTExtra;
                }

                .close-nav {
                    float: right;
                    background-image: url('/assets/img/icons/close-menu.svg');
                    height: 40px;
                    width: 40px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    margin-right: 2px;
                    background-size: 100%;
                    margin-top: -20px;
                    cursor: pointer;
                    border: 0;

                    &.tabbed {
                        outline-color: #444;
                    }
                }
            }

            &.show-controls {
                .back-button {
                    display: inline-block;
                    background-image: url('/assets/img/arrows/arrow-left.svg');
                    background-repeat: no-repeat;
                    background-position: left;
                    padding-left: 25px;
                }

                .menu-title {
                    display: none;
                }
            }

            .back-button {
                display: none;
            }

            .menu-title {
                display: inline-block;
                font-family: $futuraPTn7;
                text-transform: uppercase;
            }

            .has-children {
                &:not(.expanded):not(.menu-item-top) {
                    &:after {
                        content: "";
                        position: absolute;
                        height: 26px;
                        right: 46px;
                        top: 13px;
                        border-left: 1px solid rgba(255, 255, 255, 0.2);
                    }

                    &.active {
                        &:after {
                            border-left: 1px solid rgba(255, 255, 255, 0.5);
                        }
                    }
                }
                /*background-image: url('/assets/img/arrows/arrow-right.svg');*/
                background-repeat: no-repeat;
                background-position: right 6px top 18px;
                background-size: 18px;
                position: relative;

                @include phone {
                    background-position: right 10px top 18px;
                }



                &.expanded {
                    border-bottom: none;
                    padding: 0;

                    > .mobile-menu-header {
                        padding-top: 10px;
                        padding-top: 15px;
                        margin-top: 0;
                        margin-bottom: 10px;
                        display: inline-block;
                    }

                    &:after {
                        content: "";
                    }

                    background-image: none;

                    .menu-header-link {
                        text-transform: none;
                        cursor: pointer;
                    }
                }
            }

            .children {
                padding: 0;
                display: none;

                &.open {
                    /*display: block;*/
                }

                li {
                    background-color: rgba(#000, 0.15);
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                    a, span {
                        padding-left: 1rem;
                    }

                    .children {
                        li {
                            a, span {
                                padding-left: 1.5rem;
                            }

                            .children {
                                li {
                                    a, span {
                                        padding-left: 2rem;
                                    }
                                }
                            }
                        }
                    }
                }

                &.expanded {
                    .menu-item:first-child {
                        border-top: 1px solid rgba(255, 255, 255, 0.2);
                    }

                    display: block;
                }
            }

            .menu-item.has-children.expanded {
                > a.mobile-menu-header {
                    font-family: $futuraPTn7 !important;
                }

                .children.expanded .mobile-menu-header {
                    font-family: $futuraPTBook;
                }
            }

            .active {
                > .children {
                    display: block;
                }
            }

            .menu-item-top {
                text-transform: uppercase;
                border-bottom: none;

                span {
                    font-family: $futuraPTExtra;
                }

                .menu-item {
                    text-transform: none;
                }

                &:not(.active),
                &:not(.show) {
                    display: none;
                }

                &.show,
                &.active {
                    display: block;
                }
            }

            .Green {
                background-color: $green;
            }

            .Blue {
                background-color: $blue;
            }

            .Orange {
                background-color: $orange;
            }

            li:not(.menu-item):not(.menu-controls) {
                padding: 15px 0;
            }

            li {
                position: relative;
                list-style: none;
                background-color: $dark-blue;
                border-top: 1px solid rgba(255, 255, 255, 0.2);

                &.active {
                    > .submenu-trigger {
                        transform: rotate(270deg);
                    }
                }

                .submenu-trigger {
                    border: 0;
                    height: 48px;
                    width: 48px;
                    position: absolute;
                    top: 1px;
                    margin: auto 0;
                    right: 0;
                    background-color: transparent;
                    background-image: url('/assets/img/arrows/arrow-right.svg');
                    background-repeat: no-repeat;
                    /*background-position: right 6px top 18px;*/
                    background-position: center center;
                    background-size: 18px;
                    transform: rotate(90deg);
                }

                a, button {
                    color: #fff;
                    margin-left: 20px;
                    font-size: 18px;
                    line-height: 1.09;

                    &.tabbed {
                        outline-color: #FFF;
                    }
                }
            }


            .parent {
                font-weight: bold;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                text-transform: uppercase;
            }
        }

        .menu-header {
            padding: .5em 0 0 0;
            width: 100%;
            margin-top: 0;
            display: none;
            position: relative;
            top: -7px;
            list-style-type: none;
            line-height: 0;

            @include desktop-down {
                top: -20px;
            }

            @include media-min-width(961px) {
                display: table;
            }

            .StartPage & {
                display: table;

                @include desktop-down {
                    display: none;
                }
            }

            .bigmenu {
                display: table-cell;
                padding: 1px 0 3px 0;
                border: none;
                margin: 0 $site-padding + 10px;
                width: 33.3333%;
                width: calc(100% / 3);
                cursor: default;
                height: 45px;
                vertical-align: middle;
                overflow: hidden;

                &:hover {
                    cursor: pointer;
                }

                p, .subtext {
                    display: none;
                    color: #fff;
                    font-family: $futuraPTn7;
                    font-weight: 600;
                    letter-spacing: -1px;
                    line-height: 14.5px;
                    font-size: 1.25rem;
                    margin: 0 0 .625em;

                    @include desktop {
                        line-height: 19.5px;
                    }
                }

                .submenu-trigger {
                    background: none;
                    border: none;
                    width: 100%;
                    text-align: left;

                    &[aria-expanded="true"] {
                        .drop:after {
                            transform: rotate(-180deg);
                        }
                    }

                    .text {
                        padding-left: 17px;
                        padding-right: 50px;
                        display: table;

                        @include desktop-up {
                            padding-top: 11px;
                        }

                        @include desktop-down {
                            text-align: center;
                            margin-left: auto;
                            margin-right: auto;
                            padding: 0 10px;
                        }
                    }

                    .drop {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        right: 0;
                        z-index: -1;
                        width: 130px;
                        height: 312%;

                        @include desktop-down {
                            display: none;
                        }

                        @include tablet {
                            border-width: 0px 0px 45px 45px;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            background-image: url('/assets/img/arrows/arrow-down.svg');
                            background-size: 30px;
                            background-position: center center;
                            background-repeat: no-repeat;
                            display: block;
                            width: 30px;
                            height: 30px;
                            right: 15px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }



                .StartPage & {
                    padding: 6px 0 13px 0;

                    @include desktop {
                        padding: 6px 0 13px 0;
                    }

                    @include tablet {
                        padding: 10px 0 10px 0;
                    }

                    @include phone {
                        padding: 20px 2px 16px 2px;
                    }

                    p, .subtext {
                        display: block;

                        @include phone {
                            display: none;
                        }
                    }

                    .text {
                        padding-right: 65px;
                    }

                    .drop {
                        width: 185px;
                        height: 208%;

                        &:after {
                            background-size: 44px;
                            width: 44px;
                            height: 44px;
                        }
                    }
                }


                @include desktop-large {

                    p, .subtext {
                        font-size: 20px;
                        line-height: 0.9;
                    }
                }

                @include desktop {
                    padding: 5px 0 8px 0;
                }

                @include tablet {
                    padding: 14px 0 14px 0;

                    p, .subtext {
                        font-size: 14px;
                        line-height: 1;
                    }
                }

                @include phone {
                    p, .subtext {
                        display: none;
                    }
                }
            }

            .bigmenu--Green {
                background-color: $light-green;

                .drop {
                    background-color: $light-green;
                    background-image: linear-gradient(to left top, $green 0%, $green 50%, transparent 50%);
                }
            }

            .bigmenu--Blue {
                background-color: $light-blue;
                width: calc(33% + 1px);
                box-shadow: -2px 0px 0px 0px $light-blue;

                .drop {
                    background-color: $light-blue;
                    background-image: linear-gradient(to left top, $blue 0%, $blue 50%, transparent 50%);
                }
            }

            .bigmenu--Orange {
                background-color: $light-orange;
                box-shadow: -2px 0px 0px 0px $light-orange;


                .drop {
                    background-color: $light-orange;
                    background-image: linear-gradient(to left top, $orange 0%, $orange 50%, transparent 50%);
                }
            }
        }

        .menu-header-link {
            font-family: $futuraPTExtra;
        }

        .menu-header-link {
            display: block;
            margin: 0;
            color: $white;
            font-weight: normal;
            margin-bottom: 5px;
            font-style: normal;
            text-transform: uppercase;
            /*font-family: $futuraPTExtra;*/
            line-height: math.div($grid-height, 22px);

            @include desktop-large {
                font-size: 22px;
                line-height: 1.09;
                letter-spacing: -0.7px;
            }

            @include desktop-down {
                font-size: 16px;
                line-height: 1.26;
                letter-spacing: -0.6px;
            }


            @include phone {
                font-size: 14px;
                line-height: 1.07;
                letter-spacing: -0.8px;
                text-align: center;
            }


            @include tablet-up {
                &:hover,
                &:focus {
                    & + .submenu {
                        display: block;
                    }
                }
            }
        }
    }
}

s

.Search {
    background-color: $dark-blue-mesh;

    [role=banner] & {
        margin: 0 $site-padding;

        @include media-small-tablet {
            padding: 0;
            margin: 0;
        }
    }

    .bd {
        position: relative;
        z-index: 1;
        padding: 20px 10px;
        border: 0;

        @include media-small-tablet {
            padding: 0;
        }

        body.errorPage & {
            margin: $grid-height 0 $grid-height-x2;
            padding: 7px 10px;
            border-top: 1px solid $border-grey;
            border-bottom: 1px solid $border-grey;

            @include media-small-tablet {
                padding: 7px 10px 7px 10px;
            }
        }
    }

    .box {
        margin: 0 0 0 0;
        background-color: $white;
    }

    .input-box {
        margin: 0 60px 0 0;

        @include media-small-tablet {
            margin: 0 75px 0 0;
        }
    }

    input[type=search] {
        -webkit-appearance: none;
        width: 100%;
        height: 32px;
        border: 10px solid red;
        color: $dark-blue-mesh;
        background-color: transparent;
        text-indent: 5px;
        border-radius: 3px 0 0 3px;

        @include media-small-tablet {
            height: 59px;
            text-indent: 30px;
        }

        &::-webkit-input-placeholder {
            color: $black;
        }
    }

    button[type=submit] {
        position: absolute;
        top: 14px;
        right: 15px;
        @include imageReplace(37px, 45px);
        margin-right: 0;
        background-color: transparent;
        border: none;
        border-radius: 0 3px 3px 0;
        @include inlineSvg("icons/magnifying-glass.svg", "icons/magnifying-glass-dark.png");

        background: {
            size: 20px auto;
            position: 50%;
        }

        body.errorPage & {
            top: 0;
        }

        @include media-small-tablet {
            top: 0;
            right: 20px;
            background-size: 25px auto;
            @include imageReplace(37px, 59px);

            body.errorPage & {
                top: 7px;
                right: 25px;
            }
        }
    }
}

body.searchPage #banner [role=search] {
    margin-bottom: 20px;

    @include media-min-max-width( $media-small-tablet, $media-large-tablet ) {
        padding: 0;
        border-top: none;
    }

    .Search {
        display: none;

        @include media-large-tablet {
            display: block;
        }
    }
}

#nav {
    padding: 0 0 10px;
    margin: 10px 0;
    border-top: 1px solid $border-grey;

    @include media-small-tablet {
        display: none;
    }
}

#top-nav {
    padding-bottom: 1px;

    .no-js {
        display: none;

        @include media-small-tablet {
            display: block !important; // Sorry, but not sorry.
            // !important overrides display none set in script
            // when toggled in mobile view.
        }
    }

    @include legacyIE {
        display: none;
    }
}

#smhi-warnings-api-header {
    display: block;
    margin-top: -10px;
    float: right;
    position: absolute;
    top: -20px;
    right: 20px;
    z-index: 2;


    @include desktop-down {
        top: 0;
    }


    @include media-min-width(961px) {
        right: 0;
    }

    .StartPage & {
        @include desktop-down {
            top: -10px;
        }
    }

    &.is-hidden {
        display: none !important;
    }
}

.smhi-api-container {
    display: block;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    &:hover {
        .smhi-api-warnings {
            text-decoration: underline;

            &:after {
                @include moveRight;
            }
        }
    }

    .smhi-number-of-warnings {
        width: 0px;
        height: 0px;
        border-style: inset;
        border-width: 0px 0 30px 28px;
        border-color: transparent transparent $yellow transparent;
        margin-bottom: 0;



        span {
            color: $dark-blue;
            text-align: right;
            top: 12px;
            left: -13px;
            position: relative;
            margin: 0 auto;
        }

        &.ten-or-more {
            span {
                left: -17px;
            }
        }
    }

    .smhi-api-textbox {
        position: relative;
        top: -8px;
        display: flex;
        align-items: flex-end;
        padding-left: 30px;

        p {
            color: #fff;
            display: inline;
            font-size: 14px;
            font-family: $futuraPTn7;
            margin-bottom: 0;
        }

        .smhi-api-warnings {
            margin-left: 4px;
            position: relative;
            padding-right: 18px;
            position: relative;
            top: 4px;

            &:after {
                content: ' ';
                display: inline-block;
                position: absolute;
                background: url('/assets/img/arrows/arrow-right.svg') no-repeat right;
                height: 14px;
                width: 14px;
                margin-top: 2px;
                right: 0;
            }
        }
    }
}
