

// ==|== Functions ===================================================== //

@use "sass:math";

@function rem($px) {
	$rem: math.div($px, 16px) * 1rem;
	@return unquote($px + "; font-size: " + $rem);
}

@function em($px, $base: 16px) {
	$em: math.div($px, $base) * 1em;
	@return $em;
}

@function val($number) {
    @return math.div($number, $number * 0 + 1);
}

@function cols($n, $outer: 0) {
    $gutters: $n - 1;
    @return ( $n * $column-width ) + ( $gutters * $gutter-width ) + $outer;
}

// Adds site padding (2 x 20px = 40px) to column width
@function paddedCols($n) {
    @return cols($n, $site-padding-x2);
}


// ==|== Mixins ===================================================== //


@mixin moveRight() {
    -ms-transform: translate(3px, 0px);
    -webkit-transform: translate(3px, 0px); 
    transform: translate(3px, 0px);
}

@mixin boxShadow(){
    box-shadow: 6px 6px 0 0 rgba(33, 54, 90, 0.11);
}

@mixin legacyIE() {
	@media all {
		.legacy-ie & {
			@content;
		}
	}
}

@mixin basic() {
	@media all {
		.css-basic & {
			@content;
		}
	}
}

@mixin advanced() {
	@media screen {
		.css-adv & {
			@content;
		}
	}
}

@mixin inlineSvg($svg, $png) {
    /*
        todo: fix below
      background-image: image-url($png);
    background-image: inline-image($svg), none;*/
    background-image: url(inspect("'"+$png+"'"));
    background-image: url(inspect("'"+$svg+"'")), none;
}

@mixin svg($svg, $png) {
    background-image: image-url($png);
    background-image: image-url($svg), none;
}

@mixin listReset( $reset-li-child-only: false ) {
	list-style-type: none;
	margin: 0;
	padding: 0;
	
    @if ($reset-li-child-only) {
        > li {
		    margin: 0;
		    padding: 0;
	    }
    }
    @else {
        li {
		    margin: 0;
		    padding: 0;
	    }
    }
}

@mixin imageReplace($w, $h) {
	display: block;
	width: $w; 
	height: 0;
	padding-top: $h;
	overflow: hidden;  
	background-repeat: no-repeat;
}

@mixin underlineLinksOnHoverOnly {
    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

@mixin underlineThisOnHoverOnly {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

@mixin fontSizeAndLineHeight( $fs, $lh ) {
    font-size: rem( $fs );
    line-height:  math.div($lh, $fs) ;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}	
}

@mixin visuallyHidden {
	position: absolute;
	top: -99999px;
	left: -99999px;
}

@mixin blockStyle {
    @include boxShadow;
    background-color: $puff-bg;
    border-top: 10px solid $default;
    margin-bottom: $site-padding !important;

    h2, .sectionHeader {
        font-size: 24px;
        line-height: 27px;
        letter-spacing: -1px;
    }

    .Green & {
        border-color: $green;
    }

    .Blue & {
        border-color: $blue;
    }

    .Orange & {
        border-color: $orange;
    }
}
