@use "sass:math";

$gutter-width: 20px;
$site-padding: 20px;
$border-width: 1px;
$columns: 12;

@mixin borderBox(){
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin getWidth($colSize){
	width: #{percentage(math.div($colSize, $columns))};
}

.container_12 {
    @include borderBox;
    padding-left: $site-padding;
    padding-bottom: 50px;
    margin: 0 auto;
    height: 100%;

    &.fullwidth {
        width: 100%;
    }


    @include tablet-down {
        padding-left: $site-padding;
        padding-right: $site-padding;
        max-width: $tablet;
    }

    @include tablet {
        margin: 0;
    }

    @include desktop {
        max-width: $desktop;
    }

    @include desktop-large {
        max-width: $desktop-large;
    }

    @include phone {
        padding-right: 10px;
    }


    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.flexbox {
    display: flex;
    flex-flow: row wrap;
}

.main-col {
    order: 1;
}
.tags {
    order: 3;
}

.col_12,
.col_8,
.col_6,
.col_4{
    @include borderBox;
    padding:#{ $site-padding*0.5} $site-padding;
   	float: left;

    &:after {
        content:"";
        display:block;
        clear:both;
    }

    @include tablet-down {
        padding: 0;
    }
}

.col_12 {
	@include getWidth(12);
}

.col_8 {
	@include getWidth(8);
}

.col_6 {
	@include getWidth(6);
}

.col_4 {
	@include getWidth(4);
}

//desktop down
.col_dl_6 {
    @media (max-width: $desktop) {
        @include getWidth(6);
    }
}

//tablet-down
.col_s_12 {
    @media (max-width: $tablet) { 
		@include getWidth(12);
    }
}

//tablet-down
.col_xs_12 {
    @media (max-width: 500px) {
        @include getWidth(12);
    }
}
