

// ==|== Extends ===================================================== //


// Clearfix:
%clearfix {
	zoom: 1; 
	
	&:before,
	&:after {
		content: "\0020"; 
		display: block; 
		height: 0; 
		visibility: hidden; 
	}
	&:after { 
		clear: both; 
	}
}



// Extend to use internal columns inside blocks
%internalColumns {
    
    .inHalves &.hasInternalColumns {
        @include media-min-width( paddedCols( 15 ) ) {
            width: percentage( 1 * 0.5 );
            float: left;   
            padding: 0 0 0 $gutter-width-half;         

            &:nth-of-type(odd) {
                clear: left;
                padding: 0 $gutter-width-half 0 0;         
            }
        }
    }

    .ThemeTeaser .inHalves &.hasInternalColumns {
        @include media-min-width( cols( 11 ) ) {
            width: percentage( 1 * 0.5 );
            float: left;   
            padding: 0 0 0 $gutter-width-half;         

            &:nth-of-type(odd) {
                clear: left;
                padding: 0 $gutter-width-half 0 0;         
            }
        }
    }

    .inThirds .Block:first-child &.hasInternalColumns {
        @include media-min-max-width( cols( 8 ), cols( 12 ) - 1px ) {
            width: percentage( 1 * 0.5 );
            float: left;   
            padding: 0 0 0 $gutter-width-half;         

            &:nth-of-type(odd) {
                clear: left;
                padding: 0 $gutter-width-half 0 0;         
            }
        }
    }

    .inThirds.in25to50 .Block:first-child &.hasInternalColumns {
        @include media-min-max-width( cols( 8 ), cols( 12 ) - 1px ) {
            width: 100%;
            float: none;   
            padding: 0;         
        }
    }

    .inThirds.in25to50 .Block:last-child &.hasInternalColumns {
        @include media-min-max-width( cols( 8 ), cols( 12 ) - 1px ) {
            width: percentage( 1 * 0.5 );
            float: left;   
            padding: 0 0 0 $gutter-width-half;         

            &:nth-of-type(odd) {
                clear: left;
                padding: 0 $gutter-width-half 0 0;         
            }
        }
        @include media-min-width( cols( 15 ) ) { 
            width: percentage( 1 * 0.5 );
            float: left;   
            padding: 0 0 0 $gutter-width-half;         

            &:nth-of-type(odd) {
                clear: left;
                padding: 0 $gutter-width-half 0 0;         
            }
        }
    }

    .inThirds.in50to25 .Block:first-child &.hasInternalColumns {
        @include media-min-width( cols( 15 ) ) { 
            width: percentage( 1 * 0.5 );
            float: left;   
            padding: 0 0 0 $gutter-width-half;         

            &:nth-of-type(odd) {
                clear: left;
                padding: 0 $gutter-width-half 0 0;         
            }
        }
    }

    .inFull &.hasInternalColumns {
        @include media-min-width( cols( 8 ) ) {
            width: percentage( 1 * 0.5 );
            float: left;   
            padding: 0 0 0 $gutter-width-half;         

            &:nth-of-type(odd) {
                clear: left;
                padding: 0 $gutter-width-half 0 0;         
            }
        }

        @include media-min-width( paddedCols( 15 ) ) {
            width: percentage( 1 * 0.25 );
            float: left;   
            padding: 0 $gutter-width-half;

            &:nth-of-type(odd) {
                clear: none;
                padding: 0 $gutter-width-half;
            }
            &:nth-of-type(5n) {
                clear: both;
            }
        }
    }

    
}
