

%facebook {
    @include inlineSvg("icons/facebook.svg", "icons/facebook.png");
    background-size: 8px 18px;
}

%twitter {
    /*@include inlineSvg("icons/twitter.svg", "icons/twitter.png");*/
    background-image: url("icons/twitter.svg");
    background-size: 18px 14px;
}

%weblog {
    @include inlineSvg("icons/weblog.svg", "icons/weblog.png");
    background-size: 14px 14px;
}

%social {
    @include inlineSvg("icons/app-mini.svg", "icons/app-mini.png");
    background-size: 24px 24px;
}

%rss {
    @include inlineSvg("icons/rss.svg", "icons/rss.png");
    background-size: 16px 16px;
}

%facebook-white {
    @include inlineSvg("icons/facebook-white.svg", "icons/facebook-white.png");
    background-size: 8px 18px;
}

%twitter-white {
    @include inlineSvg("icons/twitter.svg", "icons/twitter-white.png");
    background-size: 16px 13px;
}

%email-white {
    @include inlineSvg("icons/email-white.svg", "icons/email-white.png");
    background-size: 22px 14px;
}

%tag {
    @include inlineSvg("/assets/img/icons/tag.svg", "icons/tag.png");
    background-size: 15px 14px;
}

%external-link {
    @include inlineSvg("icons/external.svg", "icons/external.png");
    background-size: 16px 12px;
}

%external-link-white {
    @include inlineSvg("icons/external-white.svg", "icons/external-white.png");
    background-size: 16px 12px;
}

%external-link-black {
    @include inlineSvg("icons/external-black.svg", "icons/external.png");
    background-size: 28px 21px;
}

%page-icon { 
    @include inlineSvg("icons/page.svg", "icons/page.png");
    background-size: 13px 16px;
}

%geotag {
    @include inlineSvg("/assets/img/icons/geo-tag.svg", "/assets/img/icons/geo-tag.png");
    background-size: 12px 16px;
}

%source {
    @include inlineSvg("icons/icon-kalla.svg", "icons/icon-kalla.png");
    background-size: 13px 15px;
}

%close {
    @include inlineSvg("icons/close.svg", "icons/close.png");
    background-size: 16px 16px;
}

%archive {
    @include inlineSvg("icons/archive.svg", "icons/archive.png");
    background-size: 32px 21px;
}

%rectangular-question-mark {
    @include inlineSvg("icons/rectangle/question-mark.svg", "icons/rectangle/question-mark.png");
    background-size: 32px 35px;
}

%sort {
    @include inlineSvg("arrows/sort.svg", "arrows/sort.png");
    background-size: 24px 15px;
}

%btn-up-blue {
    @include inlineSvg("arrows/btn-up-blue.svg", "arrows/btn-up-blue.png");
    background-size: 18px 11px;
}

%btn-down {
    @include inlineSvg("arrows/btn-down.svg", "arrows/btn-down.png");
    background-size: 18px 11px;
}

%btn-down-blue {
    @include inlineSvg("arrows/btn-down-blue.svg", "arrows/btn-down-blue.png");
    background-size: 18px 11px;
}

%btn-right {
    @include inlineSvg("arrows/btn-right.svg", "arrows/btn-right.png");
    background-size: 11px 18px;
}

%arrow-right-thick {
    @include inlineSvg("arrows/arrow-right-thick.svg", "arrows/arrow-right-thick.png");
    background-size: 18px 26px;
}