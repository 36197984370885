/* 

Normalize
=========
Customized Normalize, based on normalize.css:
http://necolas.github.io/normalize.css/

*/

html {
	font: -apple-system-body;
	font-family: sans-serif; 
	-ms-text-size-adjust: 100%; 
	-webkit-text-size-adjust: 100%; 
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*, *:before, *:after {
	-webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block; 
	vertical-align: baseline; 
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
	display: none;
}

// Remove the gray background color from active links in IE 10.
a {
	background: transparent;
}

// Improve readability when focused and also mouse hovered in all browsers.
a:active,
a:hover {
	outline: 0;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

h1 {
	font-size: 2em;
	margin: 0 0 .5em 0;
}

mark {
	background: #ff0;
	color: #000;
}

small {
	font-size: 80%;
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
	display: block;
	max-width: 100%;
	height: auto;
}

// Correct overflow not hidden in IE 9/10/11.
svg:not(:root) {
	overflow: hidden;
}

// Address margin not present in IE 8/9 and Safari.
figure {
	margin: 1em 40px;
}

hr {
	height: 1px;
	background-color: #000;
	border: none;
}

pre {
	overflow: auto;
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit; 
	font: inherit; 
	margin: 0; 
}

// Address `overflow` set to `hidden` in IE 8/9/10/11.
button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"], 
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; 
	cursor: pointer; 
}

button[disabled],
html input[disabled] {
	cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input {
	line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
	padding: 0; 
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}


fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0; 
	padding: 0; 
}

textarea {
	overflow: auto;
}

optgroup {
	font-weight: bold;
}

table {
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
	text-align: left;
}
