/*

Viewport
=========================
Viewport goes first in the stylesheet. Needed for IE10 snap mode.  */
@-ms-viewport { width : device-width }


// ==|== Shared settings, functions & mixins. No generated CSS! ================ //

@import "include/settings";
@import "include/colors";
@import "include/mixins";
@import "include/media-query-mixins";



@import "core/normalize";
// Document 
@import "core/extends";
@import "core/inline-images";
@import "core/main";
@import "core/block-layout";
@import "core/grid";
/*
# Site header and footer
*/
@import "core/header";
@import "core/footer";
/*
# Components
*/
@import "core/lists";
@import "core/footer";
@import "module/searchfield";


.errorPage {

    .Header {
        height: 140px;

        @include desktop-down {
            height: 100px;
        }
    }

    #main {
        min-height: 100%;
        padding: 20px;
    }

     body {
         padding-bottom: 350px;
     }

     .searchfield {
         margin: 20px 0;
         max-width: 700px;

         input[type="search"] {
            background-color: #fff;
            border: 6px solid #CFDAEA;
            outline: none;
            padding: 14px;
            -webkit-appearance: textfield;
         }

         ::-webkit-input-placeholder { 
            color: grey;
        }
        ::-moz-placeholder { 
            color: grey;
        }
        :-ms-input-placeholder { 
            color: grey;
        }
        :-moz-placeholder { 
            color: grey;
        }
     }
}