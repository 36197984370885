
/*

## RSS Feed

RSS feed

    <div class="RssFeed">
        <h2>MSB - Brandfarligt &amp; explosivt</h2>
        <ul>
            <li>
                <a target="_blank" href="https://www.msb.se/sv/Om-MSB/Nyheter-och-press/Nyheter/Nyheter-Brandfarligt--explosivt/Nya-foreskrifter-och-allmanna-rad-om-cisterner-och-rorledningar-for-brandfarliga-vatskor/"> Nya foreskrifter och allmanna rad om cisterner och rorledningar for brandfarliga vatskor  </a>
                <div class="meta">Publicerad: 1 okt 2014 09:48 </div>
            </li>
            <li>
                <a target="_blank" href="https://www.msb.se/sv/Om-MSB/Nyheter-och-press/Nyheter/Nyheter-Brandfarligt--explosivt/Allmanna-rad-om-forsaljning-av-fyrverkerier-upphavs/"> Allmanna rad om forsaljning av fyrverkerier upphavs  </a>
                <div class="meta">Publicerad: 16 sep 2014 09:50 </div>
            </li>
        </ul>
    </div>

    
*/

.RssFeed {
    @include blockStyle;
    min-height: 50px;

    .sectionHeader {
        margin-left: 10px;

        a {
            color: $dark-blue;
        }

        &:hover {
            &:after {
                @include moveRight;
            }
        }
    }

    &:before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 8px;
        right: 9px;
        @extend %rss;

        background: {
            position: 50% 50%;
            repeat: no-repeat;
        }
    }


    > ul {
        @include listReset;

        > li {
            border-top: 1px solid $border-grey;
            padding: 10px;

            > a {
                font-family: $futuraPTBook;
                font-size: $base-font-size;

                @include phone {
                    font-size: $base-font-size-phone;
                }
            }

            > p {
                margin: 0;
            }

            > .meta {
                @include fontSizeAndLineHeight( 13px, 13px );
                color: $text;
                margin-bottom: $grid-height - 13px;
            }
        }
    }


    &.isFacebook {
        &:before {
            @extend %facebook-white;
        }

        > .hd {
            min-height: 34px;
            background-color: $share;
            border-radius: 6px 6px 0 0;
            border: 1px solid $share;
            margin: -1px -1px 1px;

            > h2 {
                color: $white;
            }
        }



        > ul > li:first-child {
            border: none;
        }
    }
}


/*

## Link list

Standard list of links

    <div class="LinkList">
        <ul>
            <li><a href="#">Brand</a></li>
            <li><a href="#">MSB</a></li>
            <li><a href="#">Skogsbrander</a></li>
            <li><a href="#">Katastrof</a></li>
        </ul>
    </div>

    
*/

.StartPage {
    .LinkList {
        a {
            color: $default;
        }
    }
}

.LinkList {
    @include blockStyle;
    padding-bottom: 10px;
    padding: 0 $site-padding $site-padding;

    .sectionHeader {

        a {
            font-family: $futuraPTBook;
        }
    }

    > .bd p {
        word-break: break-word;
        font-size: $base-font-size;

        @include phone {
            font-size: $base-font-size-phone;
        }
    }

    ul,
    .bd > ul {
        padding-left: $site-padding;
    }

    li, p {
        position: relative;
        margin: 10px 0;
        font-size: $base-font-size;

        @include phone {
            font-size: $base-font-size-phone;
        }

        a {
            text-decoration: underline;
            font-family: $futuraPTBook;
            font-size: $base-font-size;

            @include phone {
                font-size: $base-font-size-phone;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    > ul > li > p {
        margin: 0 10px;
    }

    > ul > li > .meta {
        @include fontSizeAndLineHeight( 13px, 13px );
        color: $text;
        margin-bottom: $grid-height - 13px;
    }
}


/*

## Breadcrumbs

Breadcrumbs.

    <div class="Breadcrumbs">
        <ul>
            <li><a href="#">Handelser och storningar</a></li>
            <li>Skogsbranden i Vastmanland</li>
        </ul>
    </div>

*/
.watermark--in-archive {
    .Breadcrumbs {
        .Green &, .Blue &, .Orange & {
            color: $text;

            a {
                color: $text;
            }
        }
    }
}

.Breadcrumbs {
    /*height: $grid-height-x2 + 1px;*/
    @include fontSizeAndLineHeight( 14px, $grid-height-x2 );
    display: none;
    text-align: left;

    .Green & {
        color: $dark-green;

        a {
            color: $dark-green;
        }
    }

    .Blue & {
        color: $blue;

        a {
            color: $blue;
        }
    }

    .Orange & {
        color: $orange;

        a {
            color: darken($orange, 1%);
        }
    }

    .Default & {
        color: $default;

        a {
            color: $default;
        }
    }

    @include tablet-up {
        display: block;
    }

    ul {
        @include listReset;
    }

    li {
        display: inline-block;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: -0.4px;
        font-family: $futuraPTn7;
        padding: 0 !important;
        margin: 0 !important;

        &:after {
            font-weight: normal;
            content: "/";
        }

        &:last-child:after {
            content: "";
        }
    }
}

/*

## PillsList

Used for navigation in top menu. 

Note: Background is set to grey in demo to show the white "pills". Do not copy containing DIV!
    
    <div style="background: #ccc; padding: 10px;">
    
    <ul class="PillsList">
        <li><a href="/menyval/lattlast/" >Easyreading</a></li>
        <li><a href="http://www.lyssna.se" target=_blank>Lyssna</a></li>
    </ul>
    
    </div>
*/
.PillsList {
    @include listReset;
    z-index: 0;

    li {
        display: inline-block;
        margin-right: 7px;
        padding-left: 9px;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        font-family: $futuraPTn7;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.3px;
        display: inline-block;
        padding: 5px;
        border-radius: 3px;
        color: $white;

        @include media-small-tablet {
            background-color: transparent;
        }
    }

    @include desktop-down {
        display: none;
    }
}

.PillsList-lillakrisinfo {
    @include listReset;
    z-index: 0;

    li {
        display: inline-block;
        margin-right: 7px;
        padding-left: 9px;

        &:last-child {
            margin-right: 10px;
        }
    }

    a {
        color: $black;
        font: {
            family: $poppins-font-family;
            size: 14px;
            style: normal;
            weight: 600;
        }

        line-height: 145%;
        /*letter-spacing: -0.3px;*/
        display: inline-block;
        padding: 5px;
        border-radius: 3px;
        /*color: $white;*/

        @include media-small-tablet {
            background-color: transparent;
        }
    }

    @include desktop-down {
        display: none;
    }
}
/*

## Tag list

Use modifier **isGeoTag** to change to geo icon.

    <ul class="TagList">
        <li><a href="#">Brand</a></li>
        <li><a href="#">MSB</a></li>
        <li><a href="#">Skogsbrander</a></li>
        <li><a href="#">Katastrof</a></li>
        <li><a href="#" class="isGeoTag">Vasteras</a></li>
    </ul>

    
*/

.tags-title {
    font-family: $futuraPTn7;
    font-size: .875rem;
    font-weight: normal;
    margin: 0;
    letter-spacing: normal;
    width: 100%;
}

.TagList {
    @include listReset;
    @include fontSizeAndLineHeight( 15px, ( $grid-height * 1.5 ) - 1px );

    li {
        @extend %tag;

        background: {
            repeat: no-repeat;
            position: right 5px center;
        }

        position: relative;
        display: inline-block;
        margin-top: 6px;
        margin-right: 2px;
        background-color: $yellow;
        border-radius: 100px;
        padding: 0 30px 0 10px;

        &.isGeoTag {
            @extend %geotag;
        }

        a {
            color: $dark-blue;
            font-family: $futuraPTn7;
            font-size: 1rem !important;
        }
    }
}

/*

## FAQ list

List of FAQ items

    <div class="Faq">
        <h2 class="sectionHeader">Branden</h2>
        <ul>
            <li>
                <a href="/faq/branden#faq-2868">
                        Vad kostar Tamiflu? 
                </a>
            </li>
            <li>
                <a href="/faq/branden#faq-2857">
                        Vad ar H7N2 for virus?7 
                </a>
            </li>
        </ul>
        <a href="/faq/branden" class="Btn arrowRight">
            Visa alla
        </a>
    </div>

*/

.Faq {
    @include blockStyle;
}

.Faq,
.FaqLists {
    padding-bottom: $site-padding;


    .sectionHeader {
        padding: $site-padding;
    }

    &.hasHiddenHeader .sectionHeader {
        display: none;
    }

    @extend %internalColumns;

    ul {
        color: $text;
        border: 1px solid $border-grey;
        border-radius: 6px;
        margin: 0 0 $grid-height-half;

        .link-list-item {
            border-bottom: 1px dashed $border-grey;
            padding: $grid-height-half 10px 0 0;

            &:hover {
                &:after {
                    @include moveRight;
                }
            }

            &:after {
                width: 12px;
                margin-left: 3px;
                margin-top: -1px;
                content: ' ';
                display: inline-block;
                position: absolute;
                background: url('/assets/img/arrows/arrow-right--default.svg') no-repeat right;
                height: 25px;
            }

            a {
                color: $dark-blue;
            }


            .Green & {
                &:after {
                    content: '';
                    background: url('/assets/img/arrows/arrow-right--green.svg') no-repeat right;
                }
            }

            .Blue & {
                &:after {
                    content: '';
                    background: url('/assets/img/arrows/arrow-right--blue.svg') no-repeat right;
                }
            }

            .Orange & {
                &:after {
                    content: '';
                    background: url('/assets/img/arrows/arrow-right--orange.svg') no-repeat right;
                }
            }

            ul {
                list-style-type: disc;
                border: none;
                margin: 10px;

                li {
                    border: none;
                    padding: 0;
                }
            }

            &:last-child {
                border: none;
            }

            h3 {
                @include fontSizeAndLineHeight( 18px, $grid-height );
                margin: 0;
            }
        }
    }
}

.FaqLists {

    .FaqSection {
        @include blockStyle;
    }

    .question {
        margin: 0;
        display: block;
        text-decoration: none;
        padding: 5px 0;
        margin-left: $site-padding;
        margin-top: 4px;
        position: relative;
        padding-right: 25px;

        @include media-tablet {
            display: inline-block;
        }

        &:hover {
            text-decoration: underline;
        }

        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            background-image: url(/assets/img/arrows/arrow-down-accordion.svg);
            height: 9px;
            width: 16px;
            background-repeat: no-repeat;
            top: 11px;
            right: 0;
        }

        p {
            font-size: 1rem;
            font-family: $futuraPTn7;
            color: $dark-blue;
            margin-bottom: 0px;
            position: relative;

            br {
                display: none;
            }
        }
    }

    h2.sectionHeader {
        a {
            color: $dark-blue;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
        }
    }

    > ul,
    .FaqSection > ul {
        padding: 0;
        overflow: hidden;
    }

    p {
        margin: 0;
    }

    .answer {
        display: none;
        margin-left: $site-padding;
        margin-top: 5px;

        .Share a {
            font-family: "FuturaPTWebHeavy",Helvetica,Arial,sans-serif;
            text-decoration: none;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        > .meta {
            @include fontSizeAndLineHeight( 13px, $grid-height-x2 );
            color: $text;
        }
    }

    &.jsActive {
        ul {
        }

        li.visible {
            position: relative;
            z-index: 1;
            border-bottom: 7px solid #EDEDED;
            margin-bottom: 15px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 7px;
                background-color: $footer-back;
            }

            .question {

                &:after {
                    transform: rotate(-180deg) translateY(50%);
                    top: 16px;
                }
            }
        }
    }
}



/*

## Pagination

Pagination in for example search

    <div class="Pagination">
        <ul>
                <li><b> 1 </b></li>
                <li><a href="?q=*&amp;pg=1">2</a></li>
                <li><a href="?q=*&amp;pg=2">3</a></li>
                <li><a href="?q=*&amp;pg=3">4</a></li>
                <li><a href="?q=*&amp;pg=1">Nasta <span></span></a></li>
        </ul>
    </div>

*/
.Pagination {
    border-top: 2px solid $border-grey;
    padding: ( $grid-height * 1.5 ) 0;

    @include phone {
        margin: 0 30px;
    }

    ul {
        @include listReset;

        li {
            display: inline-block;
            margin-right: 1em;

            > a, > span {
                display: inline-block;
                padding: 0 10px;
            }
        }
    }

    @include underlineLinksOnHoverOnly;

    a {
        font: {
            size: rem(16px);
            weight: bold;
        }

        &.textLink {
            font-size: rem(14px);
        }

        span {
            color: $text;
            font-size: rem(18px);
        }
    }

    .current {
        border-bottom: 2px solid $border-grey;
    }
}

.Pagination-lillakrisinfo {
    padding: ( $global-padding ) 0;
    margin-top: $global-margin*2;

    ul {
        text-align: center;
        padding-left: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin-right: 0;
            border-radius: 64px;

            > a, > span {
                text-decoration: none;
                display: inline-block;
                padding: 7px;
                color: $offblack;
                line-height: 155%;

                font: {
                    size: 16px;
                    family: $open-sans-font-family;
                    weight: bold;
                }

                &.pageNumberLink {
                    width: 40px;
                    height: 40px;
                }

                &.textLink {
                    height: 40px;

                    font: {
                        family: $poppins-font-family;
                    }

                    &:hover {
                        padding: 8px 12px 6px 12px;
                    }
                }
            }

            &:hover {
                background: $primary-green;

                a {
                    text-decoration: underline;
                    color: $white;
                }

                a .fa-chevron-right:before {
                    color: $white;
                }

                a .fa-chevron-left:before {
                    color: $white;
                }
            }
        }
    }

    i {
        margin-left: $global-margin*0.5;
        font-size: 14px;
        color: $offblack;

        &.fa-chevron-left {
            margin-left: 0;
        }
    }

    .current {
        background: $primary-green;

        a {
            color: $white;
        }
    }
}
