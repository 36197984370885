﻿.searchfield {
    position: relative;
    width: 70%;
    margin: auto;

    @include desktop-down {
        width: 85%;
    }

    input[type="search"] {
        font-family: $futuraPTBook;
        font-size: 20px;
        margin: 0 0 em($grid-height-half, 16px);
        background-color: $yellow;
        width: 100%;
        border: none;
        border-radius: 100px;
        padding: 20px;
        -webkit-appearance: textfield;
        border: 4px solid #001439;
        padding: 16px;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #000a1d;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #000a1d;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #000a1d;
        }

        @include phone {
            font-size: 16px;
        }

        &:focus {
            border: 6px solid #001439;
            outline: none;
            padding: 14px;
        }
    }
    /* clears the 'X' from Internet Explorer */
    input[type=search]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    input[type=search]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    button[type="submit"] {
        background-color: transparent;
        right: 10px;
        border: none;
        position: absolute;
        top: 6px;
        font-size: 26px;
        font-weight: bold;
        font-family: "FuturaPTWebHeavy",Helvetica,Arial,sans-serif;
        text-transform: uppercase;

        @include phone {
            font-size: 18px;
            top: 10px;
        }

        &::after {
            content: "";
            position: relative;
            top: 10px;
            display: inline-block;
            background: url(/cassette.axd/file/assets/img/icons/magnifying-glass-black-9a6ff24….svg) no-repeat;
            width: 37px;
            height: 36px;
            fill: #000a1d;
            margin-left: 5px;

            @include phone {
                width: 27px;
                height: 26px;
                top: 8px;
            }
        }
    }
}
