
// ==|== Media Queries and Named Media Queries ===================================================== //


@mixin media-min-width($target) {
	@media (min-width: $target) { 
		@content; 
	}
}

@mixin media-max-width($target) {
    @media (max-width: $target) {
        @content;
    }
}

@mixin media-min-max-width($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}

//    Cols  Width (incl/excl outer padding)
//    ----  --------------------------
//     1      100 /   60
//     2      180 /  140
//     3      260 /  220
//     4      340 /  300
//     5      420 /  380
//     6      500 /  460
//     7      580 /  540
//     8      660 /  620
//     9      740 /  700
//    10      820 /  780
//    11      900 /  860
//    12      980 /  940
//    13     1060 / 1020
//    14     1140 / 1100
//    15     1220 / 1180
//    16     1300 / 1260

//    iOS device widths
//    -----------------
//     320    iPhone 1-5 portrait
//     375    iPhone 6 portrait
//     414    iPhone 6+ portrait
//     480    iPhone 1-4 landscape
//     568    iPhone 5 landscape
//     667    iPhone 6 landscape
//     736    iPhone 6+ landscape
//     768    iPad portrait
//    1024    iPad landscape

@mixin media-cols( $n ) {
	@media (min-width: paddedCols( $n ) ) {
		@content; 
	}
}


$media-large-mobile: paddedCols( 6 );
$media-small-tablet: paddedCols( 8 );
$media-tablet: 768px;
$media-large-tablet: paddedCols( 11 );
$media-desktop: paddedCols( 13 );
$media-site-max: cols( 16 );
$media-full: paddedCols( 16 );

//new
$phone: 320px;
$tablet: 768px;
$desktop: 960px;
$desktop-large: 1180px;
$desktop-x-large: 1440px;

@mixin media-large-mobile {
	@media ( min-width: $media-large-mobile ) { // 500
		@content; 
	}
}

@mixin media-small-tablet {
	@media ( min-width: $media-small-tablet ) { // 660
		@content; 
	}
}

@mixin media-tablet-up { //768
	@media ( max-width: $media-tablet ) { 
		@content; 
	}
}

@mixin media-tablet { //768
	@media ( min-width: $media-tablet ) { 
		@content; 
	}
}

@mixin media-large-tablet {
	@media ( min-width: $media-large-tablet ) { // 900
		@content; 
	}
}

@mixin media-desktop {
	@media ( min-width: $media-desktop ) { // 1060
		@content; 
	}
}

@mixin media-site-max {
	@media ( min-width: $media-site-max ) { // 1260
		@content; 
	}
}

@mixin media-full {
	@media ( min-width: $media-full ) { // 1300
		@content; 
	}
}




/*  MEDIA */

@mixin desktop-x-large {
    @media (min-width: $desktop-x-large) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: $desktop-large) {
        @content;
    }
}

@mixin desktop-large-down {
    @media (max-width: $desktop-large) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: $desktop) and (max-width: $desktop-large) {
        @content;
    }
}

@mixin desktop-up {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin desktop-down {
    @media (max-width: $desktop) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet) and (max-width: $desktop) {
        @content;
    }
}


@mixin tablet-up {
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin tablet-down {
    @media (max-width: $tablet) {
        @content;
    }
}


@mixin phone {
    @media (max-width: $tablet) {
        @content;
    }
}

@mixin phone-up {
    @media (min-width: $phone) {
        @content;
    }
}




@mixin high-res {
    @media only screen and (-moz-min-device-pixel-ratio: 1.5), 
            only screen and (-o-min-device-pixel-ratio: 3/2), 
            only screen and (-webkit-min-device-pixel-ratio: 1.5), 
            only screen and (min-devicepixel-ratio: 1.5), 
            only screen and (min-resolution: 1.5dppx) { 
        @content;
    }
}