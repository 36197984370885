.Footer {
    background-color: $dark-blue;
    @include underlineLinksOnHoverOnly;
    position: relative;
    z-index: 1;
    text-align: center;

    .logo {
        background-image: none;
        display: block;
        width: 100%;
        height: auto;
        padding: 0;

        img {
            /*display: inline-block;*/
            margin: 0 auto;
            width: 100%;
            max-width: 500px;
            width: 85%;
            padding: 93px 0;

            @media (max-width: 540px) {
                padding: 50px 0;
            }

            &.en {
                display: none;
            }
        }

        .english & {
            img {
                &.sv {
                    display: none;
                }

                &.en {
                    display: block;
                }
            }
        }




        @media (max-width: 350px) {
        }
    }

    .BlockSocial {

        @media (max-width: 540px) {
            margin-top: 0;
        }

        h2 {
            color: $white;
            padding-top: 0;
            font-size: 26px;
            padding-bottom: 1.5rem;
            width:100%;
        }
    }

    a {
        display: block;
        color: #fff;
        font-size: 14px;
        background-position: center top;
        padding-top: 50px;
        background-repeat: no-repeat;
        background-size: 43px;

        &.twitter {
            background-image: url('/assets/img/icons/social/tw@2x.png');
        }

        &.facebook {
            background-image: url('/assets/img/icons/social/fb@2x.png');
        }

        &.blog {
            background-image: url('/assets/img/icons/social/blog@2x.png');
        }

        &.linkedin {
            background-image: url('/assets/img/icons/social/in@2x.png');
        }

        &.instagram {
            background-image: url('/assets/img/icons/social/insta@2x.png');
        }
    }

    .section {
        padding: 0;
        max-width: 100%;
        background-color: $grey-blue;
        color: #fff;

        .english & {
            img {
                &.sv {
                    display: none;
                }

                &.en {
                    display: block;
                }
            }
        }

        img {
            width: 85%;
            max-width: 150px;
            padding: 17px 0;
            margin: 0 auto;

            &.en {
                display: none;
            }
        }
    }

    ul {
        @include listReset;
        margin-bottom: 50px;

        li {
            display: inline-block;
            width: 65px;

            @include phone {
                //margin-top: 65px;
            }
        }
    }
}
