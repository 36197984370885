
// ==|== Fonts =============================================================== //

$font-main: "Helvetica Neue", Helvetica, Arial, sans-serif; 
$font-serif: Georgia, serif;
$futuraPT: "FuturaPTWebBold", Helvetica, Arial, sans-serif;
// $futuraPT --> $futuraPTn7 (om inte h1:a)
$futuraPTn7: "FuturaPTWebHeavy", Helvetica, Arial, sans-serif;  
$futuraPTExtra: "FuturaPTWebExtraBold", Helvetica, Arial, sans-serif;
// $futuraPTExtra inte i puffar -> $futuraPTn7 (heavy)
$futuraPTBook: "FuturaPTWebBook", Helvetica, Arial, sans-serif; 
$base-font-size: 1.25rem;
$base-font-size-phone: 1.1rem;

// ==|== Fonts Lilla Krisinfo =============================================================== //

$poppins-font-family: "Poppins", sans-serif;
$open-sans-font-family: "Open Sans", sans-serif;
$fa-font-family: "Font Awesome 6 Free";

$global-margin: 1rem;
$global-padding: 1rem;

// ==|== Sizes =============================================================== //


$site-padding: 20px;
$site-padding-x2: $site-padding * 2;

$gutter-width: 20px;
$gutter-width-half: ( $gutter-width * 0.5 );
$column-width: 80px;
$column-count: 12;
$grid-height: 20px;
$grid-height-x2: $grid-height * 2;
$grid-height-x15: $grid-height * 1.5;
$grid-height-half: $grid-height * 0.5;


$content-max-width: ( $column-width * $column-count ) + ( $gutter-width * ( $column-count - 1 ) ) ;
$site-max-width: $content-max-width;

$image-overlay-opacity: 0.5;

// ==|== Foundation Breakpoints MSB for reference =============================================================== //

/*$breakpoints: ( small: 0, medium: 640px, large: 1024px, xlarge: 1200px, xxlarge: 1440px, );
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge);*/